<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Facturas solicitadas</span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              @click="initialize( )"
              small
              tile
            >
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="space-between">
	  		  		<v-col cols="12" md="3" lg="2">
                <v-select
                  :items="['PENDIENTE','ACEPTADA','RECHAZADA','TODAS']"
                  v-model="estatusFactura"
                  label="Estatus"
                  filled
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="8" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterFacturas"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:item.pdf="{ item }">
							    	<v-btn 
							    		v-if="item.pdf"
							    		color="blue" 
							    		dark
							    		x-small
							    		@click="descargar(item.pdf)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-download</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:item.xml="{ item }">
							    	<v-btn 
							    		v-if="item.xml"
							    		color="blue" 
							    		dark
							    		x-small
							    		@click="descargar(item.xml)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-download</v-icon>
							    	</v-btn>
							    </template>

                  <template v-slot:item.tiempo_transcurrido="{ item }">
                    <v-chip v-if="item.tiempo_transcurrido <= 2 || ( item.pdf && item.xml )" color="green" dark small>{{ item.tiempo_transcurrido }} días</v-chip>
                    <v-chip v-if="item.tiempo_transcurrido > 2 && ( !item.pdf || !item.xml )" color="red" dark small>{{ item.tiempo_transcurrido }} días</v-chip>
                  </template>

							    <template v-slot:item.aceptada="{ item }">
							    	<v-chip v-if="( !item.xml || !item.pdf ) && item.aceptada != 2" color="grey" dark small>PENDIENTE</v-chip>
							    	<v-chip v-if="item.xml && item.pdf" color="green" dark small>ACEPTADA</v-chip>
							    	<v-chip v-if="item.aceptada == 2" color="black" dark small>RECHAZADA</v-chip>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idsolicitar_factura }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12">
              <b>Alumno:</b> {{ editedItem.alumno }}
              <br/>
              <b>Razón Social:</b> {{ editedItem.razon_social }}
              <br/>
              <b>RFC:</b> {{ editedItem.rfc }}
              <br/>
              <b>Dirección:</b> {{ editedItem.direccion }}
              <br/>
              <b>Municipio:</b> {{ editedItem.municipio }}
              <br/>
              <b>Código posta:</b> {{ editedItem.cp }}
              <br/>
              <b>Teléfono:</b> {{ editedItem.telefono }}
              <br/>
              <b>Correo:</b> {{ editedItem.correo }}
              <br/>
              <b>Régimen:</b> {{ editedItem.regimen_fiscal }}
              <br/>
              <b>Uso CFDI:</b> {{ editedItem.uso_cfdi }}
              <br/>
              <b>Plantel:</b> {{ editedItem.plantel }}
              <br/>
              <b>Subtotal:</b> {{ editedItem.subtotal }}
              <br/>
              <b>Total:</b> {{ editedItem.total }}
              <br/>
              <b>Cuenta:</b> {{ editedItem.cuenta_escuela }}
              <br/>
              <b>Metódo pago:</b> {{ editedItem.metodo_pago }}
              <br/>
              <b>Ciclo:</b> {{ editedItem.ciclo }}
              <br/>
              <br/>

              <embed :src="url + editedItem.constancia" type="application/pdf" width="100%" height="600px" v-if="editedItem.constancia"/>


              <b>Notas:</b> {{ editedItem.observaciones }}

            </v-col>
            <v-col cols="12" v-if="!editedItem.xml || !editedItem.pdf">
					    <v-file-input
					      small-chips
					      multiple
					      label="Selecciona los archivos a cargar"
					      v-model="archivos"
					    ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            dark
            @click="close"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            color="black"
            class="mr-2"
            dark
            @click="rechazarFactura( )"
          >
            Rechazar
          </v-btn>

          <v-btn
          	v-if="!editedItem.xml || !editedItem.pdf"
            color="primary"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRechazar"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title class="text-subtitle-1">
          ¿Deseas rechazar está factura?
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editedItem.motivo_rechazo"
            :rows="3"
            auto-grow
            filled
            label="Agrega el motivo de rechazo"
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="success"
            @click="saveFactura( )"
          >
            Guardar
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  export default {
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      estatusFactura:'TODAS',
      editedIndex: -1,

      editedItem: {
      	idsolicitar_factura:'',
				alumno:'',
				rfc:'',
				regimen_fiscal:'',
				direccion:'',
				municipio:'',
				cp:'',
				telefono:'',
				correo:'',
				uso_cfdi:'',
				plantel:'',
				subtotal:'',
				total:'',
				cuenta_escuela:'',
				ciclo:'',
				id_usuario:'',
				razon_social:'',
				sucursal:'',
				ciclo:'',
        motivo_rechazo: '',
        aceptada: null
      },

      defaultItem: {
        idsolicitar_factura:'',
				alumno:'',
				rfc:'',
				regimen_fiscal:'',
				direccion:'',
				municipio:'',
				cp:'',
				telefono:'',
				correo:'',
				uso_cfdi:'',
				plantel:'',
				subtotal:'',
				total:'',
				cuenta_escuela:'',
				ciclo:'',
				id_usuario:'',
				razon_social:'',
				sucursal:'',
				ciclo:'',
        motivo_rechazo: '',
        aceptada: null
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      facturasSolicitadas: [],
      headers: [
        { text: 'ID'             , value: 'idsolicitar_factura'  },
        { text: 'Solicitó'       , value: 'nombre_completo'      },
        { text: 'Alumno'         , value: 'alumno'               },
        { text: 'Subtotal'       , value: 'subtotal'             },
        { text: 'Total'          , value: 'total'                },
        { text: 'Cuenta'         , value: 'cuenta_escuela'       },
        { text: 'Plantel'        , value: 'plantel'              },
        { text: 'Ciclo'          , value: 'ciclo'                },
        { text: 'Aceptada'       , value: 'aceptada'             },
        { text: 'Transcurrido'   , value: 'tiempo_transcurrido'  },
        { text: 'XML'            , value: 'xml'                  },
        { text: 'PDF'            , value: 'pdf'                  },
        { text: 'Actions'        , value: 'actions', sortable: false },
      ],

      alumnos:[],
      municipios:[],
      regimen:[],
      cfdi:[],
      ciclos:[],
      sucursales:[],
      archivos:[],
      dialogRechazar: false,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar datos fiscales' : 'Editar datos fiscales'
      },

      filterFacturas(){
        if( this.estatusFactura == 'TODAS'){
          return this.facturasSolicitadas
        }

        if( this.estatusFactura == 'PENDIENTE'){
          return this.facturasSolicitadas.filter( el => { return (!el.xml || !el.pdf ) && el.aceptada != 2 })
        }

        if( this.estatusFactura == 'ACEPTADA'){
          return this.facturasSolicitadas.filter( el => { return el.xml && el.pdf })
        }

        if( this.estatusFactura == 'RECHAZADA'){
          return this.facturasSolicitadas.filter( el => { return el.aceptada == 2 && ( !el.xml || !el.pdf ) })
        }
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.id_alumno" ( val ){
      	if( val ){
      		this.editedItem.cp               = val.cp
      		this.editedItem.direccion        = val.direccion
      		this.editedItem.municipio        = val.municipio
      		this.editedItem.regimen_fiscal   = val.regimen
      		this.editedItem.telefono         = val.telefono
      		this.editedItem.rfc              = val.rfc
      		this.editedItem.correo           = val.correo
      		this.editedItem.razon_social     = val.razon_social
      		this.editedItem.alumno           = val.alumno
          this.editedItem.uso_cfdi         = val.uso_cfdi
      		this.editedItem.constancia       = val.constancia
      	}else{
      		this.editedItem.cp               = ''
      		this.editedItem.direccion        = ''
      		this.editedItem.municipio        = ''
      		this.editedItem.regimen_fiscal   = ''
      		this.editedItem.telefono         = ''
      		this.editedItem.rfc              = ''
      		this.editedItem.correo           = ''
      		this.editedItem.razon_social     = ''
      	}
      }
    },

    async created () {
      this.url   = axios.defaults.baseURL + 'constancia-fiscal/'
      await this.initialize()
      await this.getAlumnosFiscales()
      await this.getUsoCFDI()
      await this.getMunicipios()
      await this.getRegimen()
      await this.getSucursales()
      await this.getCiclos()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.facturasSolicitadas = []
        return this.$http.get('facturas.solicitadas').then(response=>{
        	this.facturasSolicitadas = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getAlumnosFiscales () {
      	this.cargar = true
        this.alumnos = []
        return this.$http.get('facturas.list').then(response=>{
        	this.alumnos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsoCFDI () {
      	this.cargar = true
        this.cfdi = []
        return this.$http.get('uso.cfdi').then(response=>{
        	this.cfdi = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getMunicipios () {
      	this.cargar = true
        this.municipios = []
        return this.$http.get('facturas.municipios').then(response=>{
        	this.municipios = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getRegimen () {
      	this.cargar = true
        this.regimen = []
        return this.$http.get('facturas.regimen.fiscal').then(response=>{
        	this.regimen = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSucursales () {
      	this.cargar = true
        this.sucursales = []
        return this.$http.get('facturas.sucursales').then(response=>{
        	this.sucursales = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('facturas.ciclos').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.facturasSolicitadas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.facturasSolicitadas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('factura.eliminar/' + this.editedItem.idsolicitar_factura ).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      saveFactura ( ) {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.aceptada = 2

        this.$http.put('factura.rechazar/' + this.editedItem.idsolicitar_factura, this.editedItem ).then(response=>{
          this.cargar = true
          this.close()
          this.dialogRechazar = false
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	for( const i in this.archivos ){
	        const grabarImage  = await this.guardarImagen(this.editedItem.idsolicitar_factura, this.archivos[i] ).then(response=> response)
      	}

      	this.initialize()
    		this.close()
      	this.cargar = false
      },

      
      guardarImagen ( id, archivo ) {
        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('files', archivo);

          // Hacemos la petición
          this.$http.post(`factura.archivos/` + id, formData ).then(response=>{
            resolve( response )
          }).catch(error=>{
            this.validarError( error.response.data.message )
          })
        })
      },

      descargar( archivo ){
        const url = axios.defaults.baseURL + 'facturas/' + archivo
      	window.open( url )
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
    	},

      rechazarFactura( ){

        this.dialogRechazar = true

      }
    },
  }
</script>


